export default {
  estaCarregando: false,
  lista: [],
 
  parametros: '',
  filtros: {
    tipo_movimentacao: [],
    idioma: [],
    situacao_aluno: [],
    situacao_contrato: [],
  
  }
}
