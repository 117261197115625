
import Router from "../router/index.js"

const hasPermission = {
  bind: (el, binding) => {
    build(el, binding)
  },

  unbind: (el, binding) => {
    el.done = undefined
    el.lockedPass = undefined
  },

  update: (el, binding) => {
    build(el, binding)
  }
}

function build (el, binding) {
  const item = binding.value
  const permissoes = JSON.parse(localStorage.getItem('permissoes'))
  const rota = Router.history.current.matched[0].meta.label
  if(!permissoes || !permissoes[rota] || !permissoes[rota][item]) {
    el.classList.add('hidden')
  }
}

export default hasPermission
