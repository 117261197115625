import { render, staticRenderFns } from "./Formulario.vue?vue&type=template&id=ed569f3e&scoped=true"
import script from "./Formulario.vue?vue&type=script&lang=js"
export * from "./Formulario.vue?vue&type=script&lang=js"
import style0 from "./Formulario.vue?vue&type=style&index=0&id=ed569f3e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ed569f3e",
  null
  
)

export default component.exports