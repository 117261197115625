export default {
  paginaAtual: 1,
  estaCarregando: false,
  order: '',
  direcao: '',
  totalItens: null,
  todosItensCarregados: false,
  lista: [],
  item: {}
}
