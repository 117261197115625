export default {
  estaCarregando: false,
  lista: [],
  resumo: [],
  tabelaContagem: [],
  parametros: '',
  filtros: {
    idioma: '',
    curso: null,
    livro: null,
    instrutor: null
  }
}
