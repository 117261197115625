export default {
  lista: [],
  estaCarregando: false,
  paginaAtual: 1,
  totalItens: null,
  todosItensCarregados: false,
  itemSelecionadoID: null,
  itemSelecionado: {
    nome: null
  }
}
