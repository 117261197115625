export default {
  /* porModulo: {
    '/cadastros/curso': {
      'form/nome': {
        modulo: 1,
        mensagem: 'nome bonito'
      }
    }
  },
  lista: [] */
}
