export default {
  parcelas: {},
  condicaoPagamento: null,
  valorTitulo: null,
  dataEmissao: null,
  paginaAtual: 1,
  estaCarregando: false,
  todosItensCarregados: false,
  totalItens: null,
  lista: [],
  itemId: null,
  item: {},
  filtros: {},
  receber: {},
  titulosQuitar: [],
  titulo: null,
  tituloDetalhes: {},
  tituloMovimentos: {},
  tituloPagamentos: {}
}
