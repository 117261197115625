export default {
  estaCarregando: false,
  lista: [],
  parametros: '',
  filtros: {
    destino: null,
    tipo_de_saida: null,
    data_saida: null,
    data_entrega: null,
    item: null,
    usuario_vendeu: null,
    usuario_entregou: null,
    situacao_item: null
  }
}
