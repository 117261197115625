export default {
  estaCarregando: false,
  lista: [],
  parametros: '',
  filtros: {
    idioma: '',
    curso: null,
    livro: null,
    data_inicial: null,
    data_final: null
  }
}
