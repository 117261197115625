export default {
  estaCarregando: false,
  lista: [],
  parametros: '',
  contatosAtivo:{},
  filtros: {
    idioma: '',
    curso: null,
    livro: null,
    instrutor: null,
    listaTipo:[]
  }
}
