export default {
  lista: [],
  estaCarregando: false,
  paginaAtual: 1,
  filtros: {
    funcionario: null,
    cargo: null,
    situacao: [],
    data_cadastro_de: '',
    data_cadastro_ate: ''
  }
}
