export default {
  estaCarregando: false,
  lista: [],
  parametros: '',
  filtros: {
    data_inicial: '',
    data_final: '',
    turmas: null,
    sala: null
  }
}
