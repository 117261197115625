export default {
  estaCarregando: false,
  lista: [],
  parametros: '',
  resumo: [],
  filtros: {
    semestre: null,
    saldo_de: null,
    saldo_ate: null
  }
}
