<template>
  <svg class="svg-inline--fa fa-w-16" viewBox="4 4 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16 9.99998V12" stroke="currentColor" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M16 20V22" stroke="currentColor" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M14 20H17C18.1 20 19 19.1 19 18C19 16.9 18.1 16 17 16H15C13.9 16 13 15.1 13 14C13 12.9 13.9 12 15 12H18" stroke="currentColor" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M7.52984 21.3916C10.5713 25.9742 16.809 27.2351 21.3916 24.1937C25.9742 21.1523 27.2351 14.9145 24.1937 10.3319C21.1523 5.74936 14.9145 4.48843 10.3319 7.52984L7.83234 9.1888" stroke="currentColor" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11.7107 11.4156L6.16596 10.2948L7.28679 4.75005" stroke="currentColor" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'IconCashback'
}
</script>

<style scoped>

</style>
