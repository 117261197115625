import { render, staticRenderFns } from "./GPersonal.vue?vue&type=template&id=cbf8c640&scoped=true"
import script from "./GPersonal.vue?vue&type=script&lang=js"
export * from "./GPersonal.vue?vue&type=script&lang=js"
import style0 from "./GPersonal.vue?vue&type=style&index=0&id=cbf8c640&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cbf8c640",
  null
  
)

export default component.exports