export default {
  paginaAtual: 1,
  listaPermissao: [],
  arvoreItens: [],
  objPermissao: {},
  estaCarregando: false,
  todosItensCarregados: false,
  totalItens: null,
  permissaoSelecionadaId: null,
  permissoes: null,
  filtros: {
    papel: null,
    modulo: null,
    acao_sistema: null
  }
}
