export default {
  estaCarregando: false,
  lista: [],
  parametros: '',
  matriculas: 0,
  rematriculas: 0,
  filtros: {
    data_inicio: '',
    data_fim: ''
  }
}
