export default {
  estaCarregando: false,
  lista: [],
  temp:[],
  dados: [],
  geral: [],
  parametros: '',
  leads:[],
  por_consultor:[],
  filtros: {
    opcoesDeImpressao: true, 
    idioma: '',
    curso: null,
    livro: null,
    instrutor: null
  },
  contatosAtivo:[],
  contatosReceptivo:[],
}
