export default {
  estaCarregando: false,
  checklist_atividade: null,
  checkedId: null,
  checklistId: null,
  atividades_diarias: [],
  atividades_semanais: [],
  atividades_mensais: [],
  atividades_atemporais: []
}
