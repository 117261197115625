export default {
  lista: [],
  estaCarregando: false,
  itemSelecionadoID: null,
  filtros: {
    tipo: null,
    data_hora_inicio: null,
    data_hora_fim: null
  },
  itemSelecionado: {
    id: null
  }
}
