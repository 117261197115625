export default {
  estaCarregando: false,
  lista: [],
  parametros: '',
  filtros: {
    turma: '',
    data_inicial: '',
    data_final: '',
    sala: ''
  }
}
