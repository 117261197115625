export default {
  estaCarregando: false,
  lista: [],
  parametros: '',
  filtros: {
    modalidadeTurma: [],
    livro: null,
    data_inicial: '',
    data_final: ''
  }
}
