<template>
<div class="body400">
      <svg aria-labelledby="Starry sky" alt="Starry sky" class="starry-sky">
    <title id="svgtitle2">Starry sky</title>
    <!-- STARS START -->
    <g class="all-stars" fill="#F6F5BC">
      <path class="stars-one" d="M148.9 151.5c-.3-.3-.6-.4-1-.4s-.7.1-1 .4c-1.6 4.9-6.2 6.2-6.2 6.2-.3.3-.4.6-.4 1s.1.7.4 1c4.9 1.6 6.2 6.2 6.2 6.2.3.3.6.4 1 .4s.7-.1 1-.4c1.6-4.9 6.2-6.2 6.2-6.2.3-.3.4-.6.4-1s-.1-.7-.4-1c-5-1.7-6.2-6.2-6.2-6.2zM93.6 "/>
      <path class="stars-two" d="M148.9 540.6c-4.9-1.6-6.2-6.2-6.2-6.2-.3-.3-.6-.4-1-.4s-.7.1-1 .4c-1.6 4.9-6.2 6.2-6.2 6.2-.3.3-.4.6-.4 1s.1.7.4 1c4.9 1.6 6.2 6.2 6.2 6.2.3.3.6.4 1 .4s.7-.1 1-.4c1.6-4.9 6.2-6.2 6.2-6.2.3-.3.4-.6.4-1s-.1-.8-.4-1zM526.3 551.7c-.3-.3-.6-.4-1-.4s-.7.1-1 .4c-1.6 4.9-6.2 6.2-6.2 6.2-.3.3-.4.6-.4 1s.1.7.4 1c4.9 1.6 6.2 6.2 6.2 6.2.3.3.6.4 1 .4s.7-.1 1-.4c1.6-4.9 6.2-6.2 6.2-6.2.3-.3.4-.6.4-1s-.1-.7-.4-1c-5-1.7-6.2-6.2-6.2-6.2zM617.4 291.9c-.3-.3-.6-.4-1-.4s-.7.1-1 .4c-1.6 4.9-6.2 6.2-6.2 6.2-.3.3-.4.6-.4 1s.1.7.4 1c4.9 1.6 6.2 6.2 6.2 6.2.3.3.6.4 1 .4s.7-.1 1-.4c1.6-4.9 6.2-6.2 6.2-6.2.3-.3.4-.6.4-1s-.1-.7-.4-1c-5-1.6-6.2-6.2-6.2-6.2zM681.9 42.7c-.3-.3-.6-.4-1-.4s-.7.1-1 .4c-1.6 4.9-6.2 6.2-6.2 6.2-.3.3-.4.6-.4 1s.1.7.4 1c4.9 1.6 6.2 6.2 6.2 6.2.3.3.6.4 1 .4s.7-.1 1-.4c1.6-4.9 6.2-6.2 6.2-6.2.3-.3.4-.6.4-1s-.1-.7-.4-1c-5-1.7-6.2-6.2-6.2-6.2zM51.1 83.9c-.3-.3-.6-.4-1-.4s-.7.1-1 .4C47.5 88.8 43 90 43 90c-.3.3-.4.6-.4 1s.1.7.4 1c4.9 1.6 6.2 6.2 6.2 6.2.3.3.6.4 1 .4s.7-.1 1-.4c1.6-4.9 6.2-6.2 6.2-6.2.3-.3.4-.6.4-1s-.1-.7-.4-1c-5.1-1.6-6.3-6.1-6.3-6.1z"/>
      <path class="all-stars" d="M148.9 151.5c-.3-.3-.6-.4-1-.4s-.7.1-1 .4c-1.6 4.9-6.2 6.2-6.2 6.2-.3.3-.4.6-.4 1s.1.7.4 1c4.9 1.6 6.2 6.2 6.2 6.2.3.3.6.4 1 .4s.7-.1 1-.4c1.6-4.9 6.2-6.2 6.2-6.2.3-.3.4-.6.4-1s-.1-.7-.4-1c-5-1.7-6.2-6.2-6.2-6.2zM93.6 318.5c-.3-.3-.6-.4-1-.4s-.7.1-1 .4c-1.6 4.9-6.2 6.2-6.2 6.2-.3.3-.4.6-.4 1s.1.7.4 1c4.9 1.6 6.2 6.2 6.2 6.2.3.3.6.4 1 .4s.7-.1 1-.4c1.6-4.9 6.2-6.2 6.2-6.2.3-.3.4-.6.4-1s-.1-.7-.4-1c-5-1.7-6.2-6.2-6.2-6.2zM132.3 169c-.2-.2-.4-.3-.6-.3s-.4.1-.6.3c-1 3.1-3.8 3.8-3.8 3.8-.2.2-.3.4-.3.6 0 .2.1.4.3.6 3.1 1 3.8 3.8 3.8 3.8.2.2.4.3.6.3s.4-.1.6-.3c1-3.1 3.8-3.8 3.8-3.8.2-.2.3-.4.3-.6 0-.2-.1-.4-.3-.6-3-.9-3.8-3.8-3.8-3.8zM585.9 269.5c-.3-.3-.6-.4-1-.4s-.7.1-1 .4c-1.6 4.9-6.2 6.2-6.2 6.2-.3.3-.4.6-.4 1s.1.7.4 1c4.9 1.6 6.2 6.2 6.2 6.2.3.3.6.4 1 .4s.7-.1 1-.4c1.6-4.9 6.2-6.2 6.2-6.2.3-.3.4-.6.4-1s-.1-.7-.4-1c-5-1.7-6.2-6.2-6.2-6.2zM723.4 540.6c-4.9-1.6-6.2-6.2-6.2-6.2-.3-.3-.6-.4-1-.4s-.7.1-1 .4c-1.6 4.9-6.2 6.2-6.2 6.2-.3.3-.4.6-.4 1s.1.7.4 1c4.9 1.6 6.2 6.2 6.2 6.2.3.3.6.4 1 .4s.7-.1 1-.4c1.6-4.9 6.2-6.2 6.2-6.2.3-.3.4-.6.4-1s-.1-.8-.4-1zM526.3 551.7c-.3-.3-.6-.4-1-.4s-.7.1-1 .4c-1.6 4.9-6.2 6.2-6.2 6.2-.3.3-.4.6-.4 1s.1.7.4 1c4.9 1.6 6.2 6.2 6.2 6.2.3.3.6.4 1 .4s.7-.1 1-.4c1.6-4.9 6.2-6.2 6.2-6.2.3-.3.4-.6.4-1s-.1-.7-.4-1c-5-1.7-6.2-6.2-6.2-6.2zM617.4 291.9c-.3-.3-.6-.4-1-.4s-.7.1-1 .4c-1.6 4.9-6.2 6.2-6.2 6.2-.3.3-.4.6-.4 1s.1.7.4 1c4.9 1.6 6.2 6.2 6.2 6.2.3.3.6.4 1 .4s.7-.1 1-.4c1.6-4.9 6.2-6.2 6.2-6.2.3-.3.4-.6.4-1s-.1-.7-.4-1c-5-1.6-6.2-6.2-6.2-6.2zM681.9 42.7c-.3-.3-.6-.4-1-.4s-.7.1-1 .4c-1.6 4.9-6.2 6.2-6.2 6.2-.3.3-.4.6-.4 1s.1.7.4 1c4.9 1.6 6.2 6.2 6.2 6.2.3.3.6.4 1 .4s.7-.1 1-.4c1.6-4.9 6.2-6.2 6.2-6.2.3-.3.4-.6.4-1s-.1-.7-.4-1c-5-1.7-6.2-6.2-6.2-6.2zM51.1 83.9c-.3-.3-.6-.4-1-.4s-.7.1-1 .4C47.5 88.8 43 90 43 90c-.3.3-.4.6-.4 1s.1.7.4 1c4.9 1.6 6.2 6.2 6.2 6.2.3.3.6.4 1 .4s.7-.1 1-.4c1.6-4.9 6.2-6.2 6.2-6.2.3-.3.4-.6.4-1s-.1-.7-.4-1c-5.1-1.6-6.3-6.1-6.3-6.1z"/>
    </g>
   
  </svg>
  <div class="notfound-copy">
    <h1>Desculpe, você não tem permissão <br/>para acessar este recurso.</h1>
    <div class="links">
    <hr/>
    <button class="btn-links" @click="home()">Home</button>
    <button class="btn-links" @click="logout()">Login</button>
      <!-- <p><a href="#">Ir para Home</a></p> -->
      <!-- <p><a href="#">Ir para Login</a></p> -->
  </div>
  </div>
      
  
  
</div>

</template>
  <style>
  
.body400 {
  margin: 0;
  padding: 0;
  background-color: #2e3090;
  /* background-color: #0a1821; */
  height: 100vh;
  width: 100vw;
  font-family: Roboto, Arial, sans-serif;
  color: #fff;
  text-align: center;
}
.not-found {
  width: 560px;
  height: 225px;
  margin-right: -10px;
}
.starry-sky {
  display: block;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}
.search-icon {
  display: inline-block;
}
.notfound-copy{
  color: #FFFFFF;
  position: fixed;
  bottom: 100px;
  right: 100px;
  text-align: center;
}
.links {
  color: #fff;
  text-align: end;
  
}
.btn-links{
  color: #fff;
  background-color: #2e3090;
  border: 1px solid #2e3090;
  border-radius: 50%;
  padding: 23px 12px;
  font-size: 22px;
  margin-left: 20px;
  text-shadow: -2px 6px 20px #f0eeb76e;
}
.line{
  border-right: 1px solid #FFF;
}
.btn-links:hover{
  transform: scale(1.1);
  transition-duration: 0.7s;
  font-weight: 400;
  color: #2e3090;
  background-color: #f8f8f8;
  border: 1px solid #f8f8f8;
}

h1 {
  font-size: 34px;
  font-weight: 400;
  text-shadow: -2px 6px 20px #f0eeb76e;
  line-height: 44px;
  text-align: end;
  
}
hr{
    background-color: #fff;
   
}

a {
  font-weight: 300;
  color: #fff;
  border-bottom: 1.5px solid #5581d4;
  text-decoration: none;
}
a:hover {
  font-weight: 300;
  color: #fff;
  border-bottom: 2px solid #fff;
  text-decoration: none;
}
/* change to alternating star opacities */
.all-stars {
  animation: blinkblink 7s linear infinite;
}

@keyframes blinkblink {  
  50% { opacity: 0.1; }
}

.body400 input[type=text]{

    color: #fff;
    background-color: #0a1821;
    padding:5px; 
    border: none; 
    border-bottom:2px solid #ccc; 
    font-size: 18px;
  }
  .body400 input[type=text]:focus {
    border-color:none; 
    border-bottom:2px solid #ccc;
  }
  </style>
<script>
import IndexedDB from '../utils/indexed-db'
export default {
  name: 'Page403',
   methods: {
    logout: function (event) {
      IndexedDB.open('influx-manager').then(db => {
          IndexedDB.clear('usuarioLogado')         
          this.$router.push('/login')
        })  
      },
      home: function () {        
          this.$router.push('/home')
      }
    }
}
</script>
