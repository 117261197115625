export default {
  estaCarregando: false,
  lista: [],
  filtros: {
    curso: null,
    livro: null,
    instrutor: null,
    modalidade_turma: null,
    turnos: [],
    situacao_turma: [],
    dia_semana: [],
    mostrar_alunos: true
  }
}
