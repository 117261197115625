export default {
  lista: [],
  estaCarregando: false,
  filtros: {
    alunos: null,
    horario: null,
    nome_turma: null,
    professor: null,
    maximo_alunos: null,
    sala: null  
  }
}
