export default {
  estaCarregando: false,
  lista: [],
  resumo: {},
  parametros: '',
  filtros: {
    idioma: '',
    curso: null,
    livro: null,
    situacao_turma: [],
    modalidade_turma: [],

  }
}
