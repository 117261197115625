<template>
  <footer class="app-footer justify-content-end">
    <!-- <span><a href="http://gatilabs.com.br" target="_blank">GATI labs.</a> &copy; 2018.</span>
    -->
  </footer>
</template>
<script>
export default {
  name: 'CFooter'
}
</script>
