import { render, staticRenderFns } from "./ModalTituloReceberBoletos.vue?vue&type=template&id=6e08a962&scoped=true"
import script from "./ModalTituloReceberBoletos.vue?vue&type=script&lang=js"
export * from "./ModalTituloReceberBoletos.vue?vue&type=script&lang=js"
import style0 from "./ModalTituloReceberBoletos.vue?vue&type=style&index=0&id=6e08a962&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e08a962",
  null
  
)

export default component.exports