import { render, staticRenderFns } from "./GTable.vue?vue&type=template&id=417d2982"
import script from "./GTable.vue?vue&type=script&lang=js"
export * from "./GTable.vue?vue&type=script&lang=js"
import style0 from "./GTable.vue?vue&type=style&index=0&id=417d2982&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports