export default {
  paginaAtual: 1,
  estaCarregando: false,
  order: '',
  direcao: '',
  todosItensCarregados: false,
  totalItens: null,
  lista: [],
  itemId: null,
  item: {}
}
