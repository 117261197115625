export default {
  estaCarregando: false,
  lista: [],
  parametros: '',
  filtros: {
    idioma: '',
    curso: null,
    livro: null,
    turma: null,
    situacao_turma: [],
  }
}
