export default {
  estaCarregando: false,
  lista: [],
  listaDetalhes: [],
  parametros: '',
   filtros: {
    data_nascimento : '',
    data_cadastro_inicial : '',
    data_cadastro_final: '',
    atendente: null,
    classificacao_aluno: null,
    pessoa_sexo: null, 
    estado_uf: null,
    escolaridade:null

  }
}
