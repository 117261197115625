export default {
  lista: [],
  listaDiario: [],
  listaLicoesRealizadas: [],
  listaAvaliacoesAluno: [],
  estaCarregando: false,
  paginaAtual: 1,
  order: '',
  direcao: '',
  totalItens: null,
  todosItensCarregados: false,
  itemSelecionadoID: null,
  contratoSelecionadoID: null,
  agendamento_personal: null,
  itemSelecionado: {
    id: null
  }
}
