<template>
   <aside class="aside-menu  hidden">

  </aside> 
  
</template>

<script>
export default {
  name: 'CAside'
}
</script>
