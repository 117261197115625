export default {
  setEmailCPF (state, value) {
    state.emailCPF = value
  },

  setSenha (state, senha) {
    state.senha = senha
  },

  setMensagemErro (state, mensagemErro) {
    state.mensagemErro = mensagemErro
  }
}
