export default {
  estaCarregando: false,
  lista: [],
  parametros: '',
  filtros: {
    idioma: '',
    curso: null,
    livro: null,
  }
}
