import { render, staticRenderFns } from "./AvaliacoesAlunos.vue?vue&type=template&id=46da928a&scoped=true"
import script from "./AvaliacoesAlunos.vue?vue&type=script&lang=js"
export * from "./AvaliacoesAlunos.vue?vue&type=script&lang=js"
import style0 from "./AvaliacoesAlunos.vue?vue&type=style&index=0&id=46da928a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46da928a",
  null
  
)

export default component.exports