export default {
  estaCarregando: false,
  lista: [],
  parametros: '',
  filtros: {
    item: '',
    valor_custo_de: null,
    valor_custo_ate: null,
    valor_venda_de: null,
    valor_venda_ate: null,
    estoque_negativo: null,
    status_item: null

  }
}
