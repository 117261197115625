export default {
  estaCarregando: false,
  lista: [],
  resumo: [],
  geral: [],
  contatosAtivo:{},
  contatosReceptivo:{},


  parametros: '',
  filtros: {   
    opcoesDeImpressao: true, 
    tipoLead:[],
  }
}
