export default {
  listaCategorias: [],
  estaCarregando: false,
  paginaAtual: 1,
  totalItens: null,
  todosItensCarregados: false,
  categoriaSelecionadaId: null,
  objCategoria: {
    nome: ''
  }
}
