export default {
  estaCarregando: false,
  lista: [],
  parametros: '',
  filtros: {
    data_entrega_inicio:'',
    data_entrega_fim:'',
    item:''
  }
}
