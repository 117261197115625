export default {
  estaCarregando: false,
  lista: [],
  estaCarregando: false,
  parametros: '',

  filtros: {
     contas: null
  }
}
