export default {
  estaCarregando: false,
  paginaAtual: 1,
  itemSelecionadoID: null,
  itemSelecionado: {
    id: null,
    descricao: null,
    situacao: null
  },
  lista: []
}
