export default {
  estaCarregando: false,
  lista: [],
  semestres: [],
  formasPagamento: [],
  filtros: {
    compararSemestre: false,
    detalhesAluno: false,
    formaPagamento: null,
    modalidade: [],
    semestre: null,
    situacao: [],
  },
  resumo: {}
}
